import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	liveDoc: {
		id: 'live_doc_type',
		defaultMessage: 'Live Doc',
		description: 'Type text for live document',
	},
	statusUpdateTitle: {
		id: 'quick-find.mercury.focus-area-status-update.title',
		defaultMessage: 'Update for {focusAreaName}',
		description: 'Focus area status update title',
	},
	issue: {
		id: 'issue_type',
		defaultMessage: 'Issue',
		description: 'Type text for issue',
	},
});
