import React, { Profiler, type RefObject, useEffect, useMemo, useRef } from 'react';

import { useIntl } from 'react-intl-next';

import { type GasPayload } from '@atlaskit/analytics-gas-types';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import {
	isGraphActivity,
	isSearchConfluenceSpace,
	isSearchResultGraphEntity,
} from '@atlassian/search-client';

import { ApplicationModes } from '../../../common/constants/application-modes';
import { PrimaryProductKeys } from '../../../common/constants/products';
import { SEARCH_DIALOG_WIDTH_OBSERVER_ID } from '../../../common/constants/quick-find';
import { useAppContext } from '../../../common/ui/app-context';
import { BulkConnectBanner } from '../../../common/ui/bulk-connect-banner';
import { Heading } from '../../../common/ui/quick-find/heading';
import { useWidthObserverRegistration } from '../../../common/ui/width-observer';
import { mergeRefs } from '../../../common/utils/merge-refs';
import { OAuthContainer } from '../../../common/utils/oauth-container';
import { onSearchDialogDismissed } from '../../../common/utils/quick-find/events/search-dialog-dismissed';
import { onSearchDialogShown } from '../../../common/utils/quick-find/events/search-dialog-shown';
import { onSearchResultsRendered } from '../../../common/utils/quick-find/events/search-results-rendered';
import {
	isPostQueryLoadingOrResults,
	isPostQueryState,
	isPreQueryState,
	State,
} from '../../../common/utils/quick-find/state';
import ShowIfEligible from '../../../controllers/application-modes';
import { useNewlyConnectedProducts } from '../../../controllers/bulk-connect';
import { ErrorBoundary } from '../../../controllers/error-boundary';
import { useOAuthHandlers } from '../../../controllers/oauth';
import {
	useQuickFindAnalytics,
	useQuickFindAttributes,
} from '../../../controllers/quick-find/analytics';
import type { QuickFindContent } from '../../../controllers/quick-find/fetch-results/types';
import {
	useQuickFindFooterOnClick,
	useQuickFindResultOnClick,
	useQuickFindResultOnContextMenu,
	useQuickFindResultOnHighlighted,
} from '../../../controllers/quick-find/utils';
import {
	useBootstrap,
	useIsHello,
	usePrimaryProduct,
	useSearchActions,
} from '../../../controllers/store';
import { useBootstrapActions } from '../../../controllers/store/bootstrap';
import { useFeatureFlaggedLocalStorageCallback as useFeatureFlaggedLocalStorageCallbackDI } from '../../../controllers/store/external-storage';
import { useContent, useIsBulkConnectModalOpen } from '../../../controllers/store/quick-find';
// eslint-disable-next-line @atlassian/tangerine/import/no-parent-imports
import { BulkConnectModal } from '../../search-page/bulk-connect-modal';

import { AssetsUnavailableBanner } from './banners/assets';
import { RovoTipBanner } from './banners/rovo';
import { Divider } from './divider';
import { ErrorScreen } from './error-screen';
import { FeedbackFooter } from './feedback-footer';
import { QuickFindFilters } from './filters';
import { JiraAdvancedSearchLink } from './jira/jira-advanced-search-link';
import { JiraSingleSiteSearch } from './jira/jira-single-site-search';
import { LandingScreen } from './landing-screen';
import { messages } from './messages';
import { NoResultsScreen } from './no-results-screen';
import { PeopleAndTeams } from './people-and-teams';
import { ProductEntity } from './product-entity';
import { ProductFilterHeader } from './product-filter-header';
import { useQueryFilterResults } from './query-filters';
import { RecentActivity } from './recent-activity';
import { RecentQuery } from './recent-query';
import { SearchResultSkeleton } from './result-skeleton';
import { SearchPageLink } from './search-page-link';
import { SearchPagePrimaryProductLink } from './search-page-primary-product-link';
import {
	isErrorItem,
	isHeadingItem,
	isLandingItem,
	isNoResultsItem,
	isPeopleAndTeamsItem,
	isRecentActivityItem,
	isRecentQueryItem,
	isSearchResultItem,
	isSkeletonItem,
	type Item,
} from './types';

const searchDialogStyles = xcss({
	display: 'flex',
	position: 'absolute',
	flexDirection: 'column',
	backgroundColor: 'elevation.surface.overlay',
	borderRadius: '4px',
	boxShadow: 'elevation.shadow.overlay',
	marginTop: 'space.100',
	width: '100%',
});

const searchResultsSectionStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.100',
	gap: 'space.025',
	maxHeight: 'calc(100vh - 220px)',
	overflowY: 'auto',
});

type SearchDialogProps = {
	dialogId: string;
	dialogRef: RefObject<HTMLDivElement>;
	inputRef?: RefObject<HTMLInputElement>;
	localQuery: string;
	setLocalQuery: React.Dispatch<React.SetStateAction<string>>;
};
export const SearchDialog = ({
	dialogId,
	dialogRef,
	localQuery,
	setLocalQuery,
	inputRef,
}: SearchDialogProps) => {
	const intl = useIntl();

	const [primaryProduct] = usePrimaryProduct();
	const { retrySearch } = useSearchActions();

	const [{ thirdPartyConfigs, products: availableProducts }] = useBootstrap();
	const content = useContent();
	const [isHello] = useIsHello();

	const allItems = useBody(content);

	useResetSessionAnalytics();
	const { fireAnalyticsEvent } = useQuickFindAnalytics();
	const { commonAttributes, nonPrivacySafeAttributes } = useQuickFindAttributes();
	useAnalyticsEvents(fireAnalyticsEvent, commonAttributes, nonPrivacySafeAttributes);

	const resultOnClick = useQuickFindResultOnClick();
	const resultOnContextMenu = useQuickFindResultOnContextMenu();
	const resultOnHighlighted = useQuickFindResultOnHighlighted();
	const footerOnClick = useQuickFindFooterOnClick();

	const queryFiltersBody = useQueryFilterResults({ localQuery, setLocalQuery, inputRef });

	const { isAdminHubAIEnabled, isRovoLLMEnabled } = useAppContext();

	let hasThirdPartyResults = false;

	const localDialogRef = useRef(null);
	useWidthObserverRegistration(SEARCH_DIALOG_WIDTH_OBSERVER_ID, localDialogRef);

	if (!content) {
		return null;
	}

	const { state } = content;

	const body = [];
	let searchResultItemBody: React.JSX.Element[] | undefined;

	for (const content of allItems) {
		if (isErrorItem(content)) {
			body.push(<ErrorScreen key={content.id} onRetry={retrySearch} />);
		}

		if (isLandingItem(content)) {
			body.push(<LandingScreen key={content.id} />);
		}

		if (isNoResultsItem(content)) {
			body.push(<NoResultsScreen key={content.id} onRetry={retrySearch} />);
		}

		if (isHeadingItem(content)) {
			body.push(
				<Heading
					key={content.id}
					id={content.id}
					text={content.data}
					includeInAnalytics={content.includeInAnalytics}
				/>,
			);
		}

		if (isSkeletonItem(content)) {
			body.push(<SearchResultSkeleton key={content.id} />);
		}

		if (isRecentQueryItem(content)) {
			body.push(<RecentQuery key={content.id} result={content.data} state={state} />);
		}

		if (isRecentActivityItem(content)) {
			body.push(
				<RecentActivity
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}

		if (isSearchResultItem(content)) {
			const productEntity = (
				<ProductEntity
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>
			);

			if (fg('enable_quickfind_3p_search_results_confluence')) {
				if (!searchResultItemBody) {
					searchResultItemBody = [];
					body.push(
						<Profiler
							id="QuickFindSearchDialogResults"
							onRender={(_, phase, actualDuration) => {
								if (phase === 'mount' && localQuery.length) {
									fireAnalyticsEvent(
										onSearchResultsRendered({
											attributes: {
												...commonAttributes,
												durationMs: actualDuration,
												hasThirdPartyResults,
											},
											nonPrivacySafeAttributes,
										}),
									);
								}
							}}
						>
							{searchResultItemBody}
						</Profiler>,
					);
				}

				searchResultItemBody.push(productEntity);
				if (isSearchResultGraphEntity(content.data)) {
					hasThirdPartyResults = true;
				}
			} else {
				body.push(productEntity);
			}
		}

		if (isPeopleAndTeamsItem(content)) {
			body.push(
				<PeopleAndTeams
					key={content.id}
					result={content.data}
					state={state}
					onClick={resultOnClick}
					onContextMenu={resultOnContextMenu}
					onHighlighted={resultOnHighlighted}
				/>,
			);
		}
	}

	const footer = (() => {
		// Post-query state with Confluence or Focus
		if (
			(primaryProduct === PrimaryProductKeys.Confluence ||
				primaryProduct === PrimaryProductKeys.Focus) &&
			isPostQueryState(state)
		) {
			return (
				<>
					<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
						<Divider />
						<SearchPageLink state={state} onClick={footerOnClick} />
					</ShowIfEligible>
					{!fg('quick_find_filters') && isPostQueryLoadingOrResults(state) && (
						<>
							<Divider />
							<SearchPagePrimaryProductLink state={state} onClick={footerOnClick} />
						</>
					)}
					<Divider />
					<FeedbackFooter />
				</>
			);
		}

		if (primaryProduct === PrimaryProductKeys.Jira) {
			return (
				<>
					<Divider />
					<SearchPageLink state={state} onClick={footerOnClick} />
					<JiraAdvancedSearchLink state={state} onClick={footerOnClick} />
					<JiraSingleSiteSearch state={state} onClick={footerOnClick} />
					<FeedbackFooter />
				</>
			);
		}

		// Pre-query footer
		return (
			<>
				<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
					<Divider />
					<SearchPageLink state={state} onClick={footerOnClick} />
				</ShowIfEligible>
				{!fg('quick_find_filters') && (
					<ShowIfEligible requiredMode={ApplicationModes.Unified}>
						<Divider />
						<SearchPagePrimaryProductLink state={state} onClick={footerOnClick} />
					</ShowIfEligible>
				)}
				<Divider />
				<FeedbackFooter />
			</>
		);
	})();

	const isAIEnabled = isRovoLLMEnabled || isAdminHubAIEnabled;

	return (
		<Box
			xcss={searchDialogStyles}
			aria-label={intl.formatMessage(messages.searchDialogAriaLabel)}
			testId={'search-dialog'}
			ref={mergeRefs(dialogRef, localDialogRef)}
			id={dialogId}
		>
			{queryFiltersBody ? (
				<Box xcss={searchResultsSectionStyles}>{queryFiltersBody}</Box>
			) : (
				<>
					<Box xcss={searchResultsSectionStyles}>
						{primaryProduct === PrimaryProductKeys.Assets && <AssetsUnavailableBanner />}
						<ShowIfEligible requiredMode={ApplicationModes.Rovo}>
							{isPreQueryState(state) &&
								(fg('3p_bulk_connect') ? (
									<ErrorBoundary>
										<>
											<BulkConnectBanner
												thirdPartyConfigs={thirdPartyConfigs}
												availableProducts={availableProducts}
												useNewlyConnectedProducts={useNewlyConnectedProducts}
												useFeatureFlaggedLocalStorageCallback={
													useFeatureFlaggedLocalStorageCallbackDI
												}
												useIsBulkConnectModalOpenQuickFind={useIsBulkConnectModalOpen}
												isOnSearchDialog
											/>
											<BulkConnectModal isOnSearchDialog />
										</>
									</ErrorBoundary>
								) : (
									<ErrorBoundary>
										<OAuthContainer
											isOnSearchDialog
											thirdPartyConfigs={thirdPartyConfigs}
											availableProducts={availableProducts}
											isHello={isHello}
											useOAuthHandlers={useOAuthHandlers}
										/>
									</ErrorBoundary>
								))}
							{showRovoTipBanner(state, isAIEnabled) && <RovoTipBanner />}
							{isPostQueryState(state) && fg('quick_find_filters') && <QuickFindFilters />}
							{isPostQueryLoadingOrResults(state) && !fg('quick_find_filters') && (
								<ProductFilterHeader availableProducts={availableProducts} />
							)}
						</ShowIfEligible>
						{body}
					</Box>
					{footer}
				</>
			)}
		</Box>
	);
};

const useBody = (content: QuickFindContent) => {
	const intl = useIntl();

	return useMemo(() => {
		if (!content) {
			return [];
		}

		const { state } = content;

		const items: Item[] = [];

		const recentsHeadingItem = {
			id: 'recent-activities',
			type: 'heading' as const,
			data: intl.formatMessage(messages.recentsHeading),
		};

		const searchResultsHeadingItem = {
			id: 'search-results',
			type: 'heading' as const,
			data: intl.formatMessage(
				fg('quick_find_user_shard_query') ? messages.forYou : messages.searchResultsHeading,
			),
		};

		if (state === State.PREQUERY_ERROR) {
			items.push({ id: 'error', type: 'error' });
		}

		if (state === State.PREQUERY_LOADING) {
			items.push(...content.recentQueries.slice(0, 3));
			items.push(recentsHeadingItem);
			for (let i = 0; i < 7; i++) {
				items.push({ id: `skeleton-${i}`, type: 'skeleton' });
			}
		}

		if (state === State.PREQUERY_NO_RESULTS) {
			items.push({ id: 'landing', type: 'landing' });
		}

		if (state === State.PREQUERY_RESULTS) {
			items.push(...content.recentQueries.slice(0, 3));
			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				const filteredRecentActivities = content.recentActivities
					.filter((activity) => activity.data.node.object.data !== null)
					.slice(0, 7);
				items.push(...filteredRecentActivities);
			}
		}

		if (state === State.POSTQUERY_ERROR) {
			items.push({ id: 'error', type: 'error' });
		}

		if (state === State.POSTQUERY_LOADING) {
			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				const filteredRecentActivities = content.recentActivities
					.filter((activity) => activity.data.node.object.data !== null)
					.slice(0, 5);
				items.push(...filteredRecentActivities);
			}

			items.push({ ...searchResultsHeadingItem, includeInAnalytics: false });
			for (let i = 0; i < 5; i++) {
				items.push({ id: `skeleton-${i}`, type: 'skeleton' });
			}
		}

		if (state === State.POSTQUERY_NO_RESULTS) {
			items.push({ id: 'no-results', type: 'no-results' });
		}

		if (state === State.POSTQUERY_RESULTS) {
			const dedupedSearchResults = content.searchResults.filter((searchResult) => {
				const isDuplicate = content.recentActivities
					.filter((activity) => activity.data.node.object.data !== null)
					.slice(0, 5)
					.some((recentlyViewedResult) => {
						const activityObject = recentlyViewedResult.data.node.object;
						const id = isGraphActivity(activityObject)
							? activityObject.data?.id
							: recentlyViewedResult.id;
						return id === searchResult.id;
					});
				return searchResult.id && !isDuplicate;
			});
			const filteredSearchResults = dedupedSearchResults.filter(
				(result) => !isSearchConfluenceSpace(result.data),
			);

			const confluenceSpaceResult = dedupedSearchResults.find((result) =>
				isSearchConfluenceSpace(result.data),
			);

			if (content.recentActivities.length > 0) {
				items.push(recentsHeadingItem);
				const filteredRecentActivities = content.recentActivities
					.filter((activity) => activity.data.node.object.data !== null)
					.slice(0, 5);
				items.push(...filteredRecentActivities);
			}

			const showSearchResultsSection = Boolean(
				dedupedSearchResults.length + content.peopleAndTeams.length + content.recentQueries.length,
			);

			if (showSearchResultsSection) {
				items.push(searchResultsHeadingItem);
				items.push(...filteredSearchResults.slice(0, confluenceSpaceResult ? 5 : 6));
				confluenceSpaceResult && items.push(confluenceSpaceResult);
				items.push(...content.peopleAndTeams.slice(0, 2));
				items.push(...content.recentQueries.slice(0, 1));
			}
		}

		return items;
	}, [content, intl]);
};

const useAnalyticsEvents = (
	fireAnalyticsEvent: (payload: GasPayload) => void,
	commonAttributes: Record<string, any>,
	nonPrivacySafeAttributes: Record<string, any>,
) => {
	useEffect(() => {
		fireAnalyticsEvent(
			onSearchDialogShown({ attributes: commonAttributes, nonPrivacySafeAttributes }),
		);

		return () => {
			fireAnalyticsEvent(
				onSearchDialogDismissed({ attributes: commonAttributes, nonPrivacySafeAttributes }),
			);
		};
		// Only want these events to fire when the dialog opens and closes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

const useResetSessionAnalytics = () => {
	const { refreshSearchSessionId } = useBootstrapActions();
	const { resetQueryVersion } = useSearchActions();

	useEffect(() => {
		refreshSearchSessionId();
		resetQueryVersion();
	}, [refreshSearchSessionId, resetQueryVersion]);
};

export const showRovoTipBanner = (state: State, isAIEnabled: boolean = false) => {
	const isCorrectState = isPreQueryState(state) || isPostQueryLoadingOrResults(state);

	if (fg('rovo_search_updated_entitlement_checks_for_team_25')) {
		return isCorrectState && isAIEnabled;
	}

	return isCorrectState;
};
