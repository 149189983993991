import { fg } from '@atlaskit/platform-feature-flags';
import React, { useCallback, useMemo } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';

import { AdvancedSearch } from '../../advanced-search/advanced-search';
import { SearchDialogProduct, type TabRendererArgs } from '../../product-router';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { messages } from './messages';
import { getSections } from './sections';

import { COMPASS_PRODUCT_ID, type CompassTabProps, useCompassSuppliers } from './suppliers';
import { CompassScope, type CompassURLGenerators } from './types';
import { UpsellTab } from './upsell-tab';
import { compassURLGenerators } from './url-generators';

const UpsellModal = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_product-search-dialog/async-chunk/compass-upsell-modal" */ './upsell-tab/upsell-modal'
	).then((mod) => ({ default: mod.UpsellModal })),
);

/**
 * Callback function to be used by PSD's permission supplier.
 * @returns all Compass scope required for Compass tab to function properly.
 */
const upsellPermissionSupplier = () => {
	const response = Object.values(CompassScope);

	return Promise.resolve(response);
};

const CompassTabWithIntl = ({
	baseUrl,
	crossFlowEnabled,
	intl,
	isCompassActivated = false,
	isUserAnonymous = false,
	postQueryItemSupplier,
	preQueryItemSupplier,
	sourceContext,
	upsellCtaButtonProps,
	userTeamConfig,
	...rest
}: WrappedComponentProps & CompassTabProps) => {
	const { advancedSearchLinkGenerator }: CompassURLGenerators = useMemo(() => {
		return compassURLGenerators(baseUrl);
	}, [baseUrl]);
	const sections = getSections(intl);

	const {
		preQueryItemSupplier: preQueryItemSupplierWithPeopleAndTeams,
		postQueryItemSupplier: postQueryItemSupplierWithPeopleAndTeams,
		preQuerySectionTitleGenerator,
	} = useCompassSuppliers(
		{ userTeamConfig, preQueryItemSupplier, postQueryItemSupplier },
		sections,
	);

	const getAdvancedSearchButtonLabel = useCallback(
		(productState: ProductStates) => {
			switch (productState) {
				case ProductStates.PreQueryLoading:
				case ProductStates.PreQuerySuccess:
				case ProductStates.PreQueryError:
				case ProductStates.PreQueryNoResult:
					return intl.formatMessage(messages.compass_view_all_components);
				default:
					return intl.formatMessage(messages.compass_view_all_results);
			}
		},
		[intl],
	);

	const upsellTabRenderer = useCallback(({ title }: TabRendererArgs) => {
		return <UpsellTab title={title} />;
	}, []);

	const shouldUpsell = !isUserAnonymous && crossFlowEnabled && !isCompassActivated;

	const permissionSupplier = shouldUpsell ? upsellPermissionSupplier : rest.permissionSupplier;

	return (
		<SearchDialogProduct
			id={COMPASS_PRODUCT_ID}
			sections={sections}
			title={intl.formatMessage(messages.compass_tab_label)}
			expandedStateInputPlaceholder={intl.formatMessage(
				messages.compass_expanded_input_placeholder,
			)}
			generateAdvancedSearchUrl={advancedSearchLinkGenerator}
			preQuerySectionTitleGenerator={preQuerySectionTitleGenerator}
			preQueryItemSupplier={preQueryItemSupplierWithPeopleAndTeams}
			postQueryItemSupplier={postQueryItemSupplierWithPeopleAndTeams}
			tabRenderer={
				!fg('jira_remove_new_lozenge_from_compass_search_tab') && shouldUpsell
					? upsellTabRenderer
					: undefined
			}
			permissionSupplier={permissionSupplier}
			{...rest}
		>
			{({ linkComponent, productState, query }) => {
				if (shouldUpsell) {
					return {
						Body: () => (
							<UpsellModal
								sourceContext={sourceContext ?? 'product search dialog'}
								ctaButtonProps={upsellCtaButtonProps}
							/>
						),
						Footer: () => <></>,
					};
				}

				const advancedSearchUrl: string = advancedSearchLinkGenerator(query);
				const message = getAdvancedSearchButtonLabel(productState);

				return {
					Header: () => (
						<AdvancedSearch
							advancedSearchMessage={message}
							advancedSearchUrl={advancedSearchUrl}
							linkComponent={linkComponent}
						/>
					),
					Footer: () => <></>,
				};
			}}
		</SearchDialogProduct>
	);
};

export const CompassTab = injectIntl(CompassTabWithIntl);
