import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	googleDocsLabel: {
		id: 'document-search-result.google-docs-label',
		defaultMessage: 'Docs',
		description: 'Google Docs subtype label',
	},
	googleSlidesLabel: {
		id: 'document-search-result.google-slides-label',
		defaultMessage: 'Slides',
		description: 'Google Slides subtype label',
	},
	googleSheetsLabel: {
		id: 'document-search-result.google-sheets-label',
		defaultMessage: 'Sheets',
		description: 'Google Sheets subtype label',
	},
	msWordLabel: {
		id: 'document-search-result.ms-word-label',
		defaultMessage: 'Word',
		description: 'Microsoft Word subtype label',
	},
	msPowerpointLabel: {
		id: 'document-search-result.ms-powerpoint-label',
		defaultMessage: 'PowerPoint',
		description: 'Microsoft PowerPoint subtype label',
	},
	msExcelLabel: {
		id: 'document-search-result.ms-excel-label',
		defaultMessage: 'Excel',
		description: 'Microsoft Excel subtype label',
	},
});
