import React, { useCallback, useMemo, useState } from 'react';

import { QuickFind as QuickFindInternal } from '@atlassian/search-page/quick-find';

import { TOUCH } from '@confluence/navdex';
import { useSessionData } from '@confluence/session-data';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { createSingleQueryParamHook, useRouteActions } from '@confluence/route-manager';
import {
	GeneralShortcutListener,
	NAVIGATION_SHORTCUT,
	NAVIGATION_SLASH_SHORTCUT,
} from '@confluence/shortcuts';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';
import { StatsigConfigurations } from '@confluence/statsig-client/entry-points/StatsigConfigurations';

const generateUserUrl = (userId: string) => `/wiki/people/${userId}`;

const generateTeamUrl = (teamId: string) => `/wiki/people/team/${teamId}`;

const useTextParameter = createSingleQueryParamHook('text');

export const QuickFind = () => {
	const [open, setOpen] = useState(false);
	const isNav4Enabled = useIsNav4Enabled();
	const {
		cloudId,
		orgId,
		activationId,
		isAdminHubAIEnabled,
		userId,
		displayName,
		isRovoLLMEnabled,
	} = useSessionData();
	const { push } = useRouteActions();

	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	const textParameter = useTextParameter();
	const queryParams = useMemo(
		() => ({
			text: textParameter,
		}),
		[textParameter],
	);

	const analyticsAttributes = useMemo(
		() => ({ activationId, navdexPointType: TOUCH }),
		[activationId],
	);

	const generateSearchUrl = useCallback((urlQueryParams: any) => {
		const url = new URL('/wiki/search', window.location.origin);
		const searchParams = new URLSearchParams(window.location.search);
		Object.entries(urlQueryParams).forEach(([key, value]) => {
			if (value !== undefined && value !== null) {
				searchParams.set(key, String(value));
			}
		});
		url.search = searchParams.toString();
		return url.href;
	}, []);

	const user = useMemo(() => ({ id: userId, name: displayName }), [userId, displayName]);

	const experimentLayers = StatsigConfigurations.getStringList(
		'search_platform_layer_functions',
		[],
	);

	const onNavigate = useCallback((url: string) => push(url), [push]);

	return (
		<>
			<QuickFindInternal
				cloudId={cloudId}
				orgId={orgId}
				primaryProduct="confluence"
				analyticsAttributes={analyticsAttributes}
				isAdminHubAIEnabled={isAdminHubAIEnabled}
				isNav4Enabled={isNav4Enabled}
				onNavigate={onNavigate}
				queryParams={queryParams}
				open={open}
				setOpen={setOpen}
				user={user}
				generateSearchUrl={generateSearchUrl}
				generateUserUrl={generateUserUrl}
				generateTeamUrl={generateTeamUrl}
				experimentLayers={experimentLayers}
				isRovoLLMEnabled={isRovoLLMEnabled}
			/>
			{!isLiveEditMode && !isOnEditRoute && (
				<GeneralShortcutListener
					accelerator={[NAVIGATION_SHORTCUT, NAVIGATION_SLASH_SHORTCUT]}
					listener={() => setOpen(true)}
					isAppNavigationShortcut
				/>
			)}
		</>
	);
};
