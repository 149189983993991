import { type GasPayload } from '@atlaskit/analytics-gas-types';

export const onAppFilterSelect = ({
	action,
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
}: {
	action: 'selected' | 'unselected';
	actionSubjectId: string;
	attributes: Record<string, any>;
	nonPrivacySafeAttributes: Record<string, any>;
}): GasPayload => ({
	eventType: 'track',
	action,
	actionSubject: 'appSelector',
	actionSubjectId,
	attributes,
	nonPrivacySafeAttributes,
});
