import { type MessageDescriptor } from 'react-intl-next';

import { ProductKeys } from '../../../constants/products';

import { messages } from './messages';

export const getDocumentSubtypeLabel = ({
	product,
	subtype,
}: {
	product?: string;
	subtype?: string;
}): MessageDescriptor | undefined => {
	if (!product || !subtype) {
		return;
	}
	const SUBTYPE_DOCUMENT = 'DOCUMENT';
	const SUBTYPE_PRESENTATION = 'PRESENTATION';
	const SUBTYPE_SPREADSHEET = 'SPREADSHEET';

	type DocumentSubtypeLabels = Record<string, MessageDescriptor>;
	const GOOGLE_LABELS: DocumentSubtypeLabels = {
		[SUBTYPE_DOCUMENT]: messages.googleDocsLabel,
		[SUBTYPE_PRESENTATION]: messages.googleSlidesLabel,
		[SUBTYPE_SPREADSHEET]: messages.googleSheetsLabel,
	};

	const MICROSOFT_LABELS: DocumentSubtypeLabels = {
		[SUBTYPE_DOCUMENT]: messages.msWordLabel,
		[SUBTYPE_PRESENTATION]: messages.msPowerpointLabel,
		[SUBTYPE_SPREADSHEET]: messages.msExcelLabel,
	};

	const normalizedSubtype = subtype?.toUpperCase();

	let label;
	if (product === ProductKeys.Sharepoint || product === ProductKeys.OneDrive) {
		label = MICROSOFT_LABELS[normalizedSubtype];
	} else if (product.includes(ProductKeys.GoogleDrive)) {
		label = GOOGLE_LABELS[normalizedSubtype];
	}

	return label;
};
